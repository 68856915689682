<template>
  <div>
    <v-container class="grey lighten-5 mb-6">
      <h3>Reconciliation Details</h3>
      <v-data-table
        dense
        :headers="defaultHeaders"
        :items-per-page="-1"
        :items="editedItem"
        hide-default-footer
        class="elevation-1"
      >
        <template v-slot:top>
          <div class="d-flex justify-end">
            <v-btn
              class="mx-2 recon-details-add-new-item"
              color="primary" fab icon x-small
              @click="addOne"
            >
              <v-icon>
                {{icons.mdiPlus}}
              </v-icon>
          </v-btn>
          </div>
        </template>

        <template v-slot:[`item.fundDescription`]="{ item }">
          <v-text-field
            v-model="item.fundDescription"
            label="Fund"
            dense
            single-line
            @keydown.enter="somethingChanged"
            @blur="somethingChanged"
          ></v-text-field>
        </template>

        <template v-slot:[`item.appFund`]="{ item }">
          <v-text-field
            v-model="item.appFund"
            label="App Fund"
            dense
            single-line
            @keydown.enter="somethingChanged"
            @blur="somethingChanged"
          ></v-text-field>
        </template>

        <template v-slot:[`item.sharedFundAltKey`]="{ item }">
          <v-text-field
            v-model="item.sharedFundAltKey"
            label="Fund Alt Key"
            dense
            single-line
            @keydown.enter="somethingChanged"
            @blur="somethingChanged"
          ></v-text-field>
        </template>

        <template v-slot:[`item.linkToMisc`]="{ item }">
          <v-text-field
            v-model="item.linkToMisc"
            dense
            single-line
            label="Link To Miscellaneous"
            @keydown.enter="somethingChanged"
            @blur="somethingChanged"
          ></v-text-field>
        </template>

        <template v-slot:[`item.remitTo`]="{ item }">
          <v-text-field
            v-model="item.remitTo"
            label="Remit To"
            dense
            single-line
            @keydown.enter="somethingChanged"
            @blur="somethingChanged"
          ></v-text-field>
        </template>

        <template v-slot:[`item.SDRType`]="{ item, index }">
            <v-select
            label="SDR Type"
            v-model="item.SDRType"
            :items="TaxUnitDetailSDRTypes"
            item-text="description"
            item-value="name"
            dense
            single-line
            @keydown="shortcutHandler($event, index);"
            @keydown.enter="somethingChanged"
            @change="somethingChanged"
          ></v-select>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small class="mr-2"
            tabindex="-1"
            @click="removeOne(item)"
          >
            {{icons.mdiDelete}}
          </v-icon>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import {
  mapGetters, mapState,
} from 'vuex';
import {
  mdiPlus,
  mdiDelete,
} from '@mdi/js';
import {
  isTabOrPlus,
} from '../../../util/shared/vue-global';

const defaultItem = {
  fundDescription: '',
  appFund: '',
  sharedFundAltKey: '',
  linkToMisc: '',
  remitTo: '',
  SDRType: null,
};

export default {
  name: 'MiscDetails',
  created() {
  },
  props: {
    baseCrudKey: {
      type: String,
      default: 'taxUnitTrans-dialog',
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    icons: {
      mdiPlus,
      mdiDelete,
    },
    defaultHeaders: [
      { text: 'Fund', value: 'fundDescription' },
      { text: 'App Fund', value: 'appFund' },
      { text: 'Fund Alt Key', value: 'sharedFundAltKey' },
      { text: 'Link To Miscellaneous', value: 'linkToMisc' },
      { text: 'Remit To', value: 'remitTo' },
      { text: 'SDR Type', value: 'SDRType' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
  }),
  computed: {
    ...mapGetters('base/crud', ['criteria', 'item', 'items']),
    ...mapState({
      TaxUnitDetailSDRTypes: (state) => state.enums.TaxUnitDetailSDRTypeEnum || [],
    }),
    editedItem: {
      get() {
        const newVal = JSON.parse(JSON.stringify(this.value));
        const updatedValue = newVal.map(this.loadInitialValues);
        return updatedValue;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    loadInitialValues(item) {
      return {
        ...item,
      };
    },
    addOne() {
      this.$emit('input', [
        ...this.editedItem,
        {
          ...defaultItem,
        },
      ]);
      return true;
    },
    removeOne(item) {
      this.$emit('input', [
        ...this.editedItem.filter((candidate) => item !== candidate),
      ]);
    },
    somethingChanged() {
      this.$emit('input', this.editedItem);
    },
    shortcutHandler(e, index) {
      if (isTabOrPlus(e)) {
        if ((index + 1) === (this.editedItem || []).length) {
          for (let i = 0; i < (this.editedItem || []).length; i += 1) {
            this.addOne();
          }
        }
      }
    },
  },
};
</script>

<style lang="sass">
  .eft-rec-amount input
    text-align: right
  .recon-details-add-new-item
    margin-top: 16px
</style>
