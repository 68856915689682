<template>
  <div>
    <v-container >
      <CRUDCriteria
        :showSearch="false"
        :showReset="true"
      >
        <template v-slot:criteriaRows>
          <v-row>
            <v-col cols="2">
              <v-select class="tax-unit-criteria-by-fiscal-year-select"
                v-model.number="criteriaState.fiscalYear"
                :items="fiscalYearRange"
                label="By Fiscal Year"
                @input="searchByCriteria"
              ></v-select>
            </v-col>
          </v-row>
        </template>
      </CRUDCriteria>
      <CRUDList
        :canAdd="true"
        :canEdit="true"
        :canSave="true"
        :headers="headers"
        :baseCrudKey="baseCrudKey"
        :createNewItem="newItemDialog"
        toolbarTitle="Tax Unit"
        @loadCRUDList="handleLoadCRUDList"
        @upsertItem="upsrtTransaction"
        :defaultSortBy="sortBy"
      >
        <template v-slot:[`editedItem`]="{}">
          <taxUnitEditedItem
            :baseCrudKey="baseCrudKey"
          />
        </template>
      </CRUDList>
    </v-container>
  </div>
</template>

<script>
import {
  mapGetters,
  mapActions,
  mapMutations,
  mapState,
} from 'vuex';
import CRUDList from '../../common/base/crud/CRUDList.vue';
import CRUDCriteria from '../../common/base/crud/CRUDCriteria.vue';
import taxUnitEditedItem from './taxUnitEditedItem.vue';
import {
  getFYList,
  toFiscalYear,
} from '../../../util/shared/tmc-global';

const getHeaders = () => {
  const headers = [
    { text: 'Section', value: 'appSection' },
    { text: '#', value: 'orderNumber' },
    { text: 'Description', value: 'description' },
    { text: 'General Line At', value: 'generalLineAt' },
    { text: 'Sub Section', value: 'subSection' },
    { text: 'Link To Unit Apportionment', value: 'toUnitDescForAppro' },
    { text: 'ADA/ABT', value: 'averageDailyAttendence' },
    { text: 'MV', value: 'motorVehicle' },
  ];
  return headers;
};

export default {
  name: 'taxUnitList',
  components: {
    CRUDCriteria,
    CRUDList,
    taxUnitEditedItem,
  },
  data: () => ({
    baseCrudKey: 'sharedTaxUnit',
    headers: [],
    sortBy: [
      {
        column: 'appSection',
        direction: 'asc',
      },
      {
        column: 'orderNumber',
        direction: 'asc',
      },
    ],
  }),
  created() {
    this.headers = getHeaders();
    this.initCriteria();
    this.criteriaState.fiscalYear = toFiscalYear(new Date(this.todaysDate));
    this.loadFiscalRegYears();
  },
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
      'index',
      'item',
    ]),
    ...mapGetters(['todaysDate']),
    ...mapState({
      fiscalYearRange: (state) => {
        const registerdYears = state.shared.taxUnit.regYears || {};
        const fyList = getFYList(registerdYears);
        return fyList.sort((a, b) => b - a);
      },
    }),
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
  },
  methods: {
    ...mapActions('shared/taxUnit', [
      'loadTaxUnit',
      'upsertTaxUnit',
      'loadFiscalRegYears',
    ]),
    ...mapMutations('base/crud', [
      'setCriteria',
    ]),
    handleLoadCRUDList() {
      const { baseCrudKey } = this;
      const criteria = this.criteriaState;
      this.loadTaxUnit({
        criteria,
        baseCrudKey,
      });
    },
    async upsrtTransaction(item) {
      const { baseCrudKey } = this;
      const updatedItem = { ...item };
      if (updatedItem.appSection !== 'B') {
        updatedItem.motorVehicle = null;
      }
      await this.upsertTaxUnit({ item: updatedItem, baseCrudKey });
    },
    newItemDialog() {
      let newItem = {};
      newItem = {
        fiscalYear: this.criteriaState.fiscalYear,
        orderNumber: '',
        description: '',
        averageDailyAttendence: 0,
        motorVehicle: 0,
        details: [{
          fundDescription: '',
          appFund: '',
          sharedFundAltKey: '',
          linkToMisc: '',
          remitTo: '',
        }],
      };
      return newItem;
    },
    initCriteria() {
      // reset criteria while merge flag is true
      this.criteriaState = {};
    },
    searchByCriteria() {
      this.handleLoadCRUDList();
    },
  },
};

</script>
