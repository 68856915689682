<template>
   <v-container>
    <v-row>
      <v-col cols="3">
        <v-select
          v-model="editedItem.fiscalYear"
          :items="fiscalYearRange"
          label="Fiscal Years"
          readonly
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model.number="editedItem.orderNumber"
          label="Order Number"
          type="number"
        >
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-select
          v-model="editedItem.appSection"
          :items="sharedBackPageEnum"
          label="App Section"
          item-text="description"
          item-value="name"
        >
        </v-select>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="editedItem.subSection"
          label="Sub Section"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-text-field
          v-model="editedItem.toUnitDescForAppro"
          label="To Unit in Levy/App"
        >
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="editedItem.description"
          label="Description"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="editedItem.generalLineAt"
          label="General Line At"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-text-field
          v-model.number="editedItem.averageDailyAttendence"
          :label="remarkCaption"
        >
        </v-text-field>
      </v-col>
      <v-col cols="3" v-if="editedItem.appSection === 'B'">
        <v-text-field
          v-model.number="editedItem.motorVehicle"
          label="MV"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-checkbox
          v-model="editedItem.isMtgTax"
          label="Mtg Tax"
        >
        </v-checkbox>
      </v-col>
      <v-col cols="3">
        <v-checkbox
          v-model="editedItem.isMVTS"
          label="Motor Vehicle Tax Stamps"
        >
        </v-checkbox>
      </v-col>
      <v-col cols="3">
        <v-checkbox
          v-model="editedItem.isJt4Mill"
          label="JT 4 Mill"
        >
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row>
        <v-col>
          <taxUnitDetails id="taxUnitEditDialogDetails"
            class="tax-unit-edit-dialog-details"
            v-model="editedItem.details"
            :baseCrudKey="baseCrudKey"
          />
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import {
  getFYList,
} from '../../../util/shared/tmc-global';
import taxUnitDetails from './taxUnitDetails.vue';

export default {
  name: 'taxUnitEditedItem',
  components: {
    taxUnitDetails,
  },
  props: {
    baseCrudKey: {
      type: String,
      default: 'taxUnit-dialog',
    },
  },
  created() {
  },
  computed: {
    ...mapState({
      sharedBackPageEnum: (state) => state.enums.sharedBackPageEnum || [],
      fiscalYearRange: (state) => {
        const registerdYears = state.shared.taxUnit.regYears || {};
        return getFYList(registerdYears);
      },
    }),
    ...mapGetters('base/crud', [
      'item',
      'items',
      'index',
      'isVisible',
    ]),
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
    remarkCaption() {
      return this.editedItem.appSection === 'B' ? 'ABT' : 'Avg Daily Attendance';
    },
  },
  methods: {
    ...mapMutations('base/crud', [
      'setItem',
    ]),
  },
};
</script>
