var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"grey lighten-5 mb-6"},[_c('h3',[_vm._v("Reconciliation Details")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.defaultHeaders,"items-per-page":-1,"items":_vm.editedItem,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mx-2 recon-details-add-new-item",attrs:{"color":"primary","fab":"","icon":"","x-small":""},on:{"click":_vm.addOne}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)],1)]},proxy:true},{key:"item.fundDescription",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"label":"Fund","dense":"","single-line":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.somethingChanged($event)},"blur":_vm.somethingChanged},model:{value:(item.fundDescription),callback:function ($$v) {_vm.$set(item, "fundDescription", $$v)},expression:"item.fundDescription"}})]}},{key:"item.appFund",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"label":"App Fund","dense":"","single-line":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.somethingChanged($event)},"blur":_vm.somethingChanged},model:{value:(item.appFund),callback:function ($$v) {_vm.$set(item, "appFund", $$v)},expression:"item.appFund"}})]}},{key:"item.sharedFundAltKey",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"label":"Fund Alt Key","dense":"","single-line":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.somethingChanged($event)},"blur":_vm.somethingChanged},model:{value:(item.sharedFundAltKey),callback:function ($$v) {_vm.$set(item, "sharedFundAltKey", $$v)},expression:"item.sharedFundAltKey"}})]}},{key:"item.linkToMisc",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"dense":"","single-line":"","label":"Link To Miscellaneous"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.somethingChanged($event)},"blur":_vm.somethingChanged},model:{value:(item.linkToMisc),callback:function ($$v) {_vm.$set(item, "linkToMisc", $$v)},expression:"item.linkToMisc"}})]}},{key:"item.remitTo",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"label":"Remit To","dense":"","single-line":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.somethingChanged($event)},"blur":_vm.somethingChanged},model:{value:(item.remitTo),callback:function ($$v) {_vm.$set(item, "remitTo", $$v)},expression:"item.remitTo"}})]}},{key:"item.SDRType",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-select',{attrs:{"label":"SDR Type","items":_vm.TaxUnitDetailSDRTypes,"item-text":"description","item-value":"name","dense":"","single-line":""},on:{"keydown":[function($event){return _vm.shortcutHandler($event, index);},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.somethingChanged($event)}],"change":_vm.somethingChanged},model:{value:(item.SDRType),callback:function ($$v) {_vm.$set(item, "SDRType", $$v)},expression:"item.SDRType"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","tabindex":"-1"},on:{"click":function($event){return _vm.removeOne(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }