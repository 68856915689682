<template>
  <v-container>
    <taxUnitList/>
  </v-container>
</template>

<script>

import taxUnitList from '../../components/shared/taxUnit/taxUnitList.vue';

export default {
  name: 'taxFund',
  components: {
    taxUnitList,
  },
};

</script>
